window.Client = {
	init: function() {
		// Do something on initialization
	},

	onRender: function(screenId) {
		// Do something when the flow renders
		document.querySelector('[data-variable="variant"]').value = windowConstants.VARIANT_ID;
	
	// Neil adding the pension wise parameter code
	
	   // END OF Neil adding the pension wise parameter code 
	    
	    
	}
}